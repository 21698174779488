import React, { Component } from 'react';
import luxy from 'luxy.js';

import { Intro } from './containers/intro';
import { Payment } from './containers/payment';
import { WhyJoin } from './containers/whyjoin';
import { ScheduleMeeting } from './containers/schedulemeeting';

export default class About extends Component {
  componentDidMount() {
    luxy.resize();
    window.location.hash !== '#kontakti' && window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="page about">
        <Intro />
        <Payment />
        <WhyJoin />
        <ScheduleMeeting />
      </div>
    );
  }
}
