import React, { Component } from 'react';
import VizSensor from 'react-visibility-sensor';
import { Link, NavLink } from 'react-router-dom';

export class Members extends Component {
  constructor() {
    super();
    this.state = {
      visible: false,
    };
  }
  componentDidMount() {}
  render() {
    const { visible } = this.state;

    return (
      <div className="container spaced members">
        <div className="wrapper">
          <a id="biedri"></a>
          <h2>
            VEF Rīga <br />
            <span className="outlined">biedri</span>
          </h2>
          <div className="list">
            {Array.from({ length: 24 }, (value, index) => {
              return (
                <div className="block">
                  <div className="item">
                    <img
                      className="image"
                      src="https://via.placeholder.com/200x120"
                    />
                    <span className="description">Janis Vilhelms Kocins</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="join-us">
            <div className="left">
              <span className="subheading">Vēlies pievienoties?</span>
              <VizSensor
                onChange={(isVisible) => {
                  if (isVisible) {
                    this.setState({ visible: true });
                  }
                }}
              >
                {/* <a className="become-a-member" href="">
                  Kļūsti par Biedru
                </a> */}

                <NavLink className="become-a-member" to={'/par-mums#kontakti'}>
                  Kļūsti par Biedru
                </NavLink>
              </VizSensor>
            </div>
            <div className="right">
              <span className="free-spaces">
                Brīvo biedru<br></br>vietu skaits
              </span>
              <h4>83/100</h4>
            </div>
            <svg
              width="869"
              height="886"
              viewBox="0 0 869 886"
              fill="none"
              className="path-overlay"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="path-main visible"
                d="M38.1472 1C27.226 26.1109 32.8426 54.965 39.7073 81.4795C84.4841 257.567 167.641 423.673 281.533 565.136C327.714 622.533 379.98 676.654 444.103 712.526C472.498 728.435 511.346 739.353 534.904 716.893C500.581 601.945 430.685 497.914 337.387 422.582C332.239 445.821 345.188 468.748 358.293 488.556C429.905 596.486 526.636 687.883 638.5 753.39C717.132 799.401 804.969 834.181 868 900"
                stroke="white"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
                strokeMiterlimit="10"
              />
              <path
                className="path-main visible"
                d="M143.704 621C146.748 632.141 150.549 643.59 151.829 655.109C152.293 659.28 153.584 663.09 154.93 667.037C156.111 670.502 158.105 673.751 159.774 676.996C161.484 680.321 163.381 683.807 165.296 687"
                stroke="white"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
                strokeMiterlimit="10"
              />
              <path
                className="path-main visible"
                d="M186.481 642.593C179.93 645.059 173.479 647.387 166.654 648.975C161.7 650.129 156.658 651.044 151.988 653.14C145.691 655.965 140.029 659.101 133.315 660.926C127.381 662.539 121.217 663.151 116 666.63"
                stroke="white"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
                strokeMiterlimit="10"
              />
              <path
                className={`path-main${visible ? ' animate' : ''}`}
                d="M1 743C25.6778 736.959 50.5626 733.486 75.8808 731.115C129.791 726.066 184.046 724.404 238.104 721.692C379.216 714.614 520.139 704.526 661.273 697.922C690.048 696.575 718.797 694.484 747.316 690.426C749.56 690.107 752.444 690.17 754.576 689.249C756.755 688.307 749.939 690.303 747.641 690.908C742.734 692.201 737.284 692.693 732.253 693.371C701.922 697.458 671.619 701.593 641.334 706.006C567.774 716.723 493.214 721.439 418.967 724.797C400.923 725.614 346.721 725.654 364.784 725.654"
                stroke="white"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
