import React, { Component } from 'react';

import art from './assets/features-maksla.png';
import veterans from './assets/features-veterani.png';
import schools from './assets/features-skolas.png';
import society from './assets/features-sabiedriba.png';
import { NavLink } from 'react-router-dom';

export class SocialProjects extends Component {
  render() {
    return (
      <div className="projects container noline">
        <div className="wrapper cutting">
          <div className="title">
            <h1 className="cutaway">
              <span className="outlined">
                Dalība
                <br />
                sociālajos
              </span>
              <br />
              projektos
            </h1>
          </div>
          <span className="subheading">
            Mēs zinām, ka ar maziem darbiem var panākt lielus rezultātus
          </span>
          <div className="list">
            <div className="block">
              <div className="item">
                <img className="image" src={art} />
                <span className="description">Atbalsts mākslai</span>
              </div>
            </div>
            <div className="block">
              <div className="item">
                <img className="image" src={veterans} />
                <span className="description">Atbalsts sporta veterāniem</span>
              </div>
            </div>
            <div className="block">
              <div className="item">
                <img className="image" src={schools} />
                <span className="description">Atbalsts skolām</span>
              </div>
            </div>
            <div className="block">
              <div className="item">
                <img className="image" src={society} />
                <span className="description">Atbalsts sabiedrībai</span>
              </div>
            </div>
          </div>
          <div className="join-us">
            <div className="left">
              <span className="subheading">
                Pieteikt tikšanos ar VEF Rīga vadību
              </span>
              {/* <a className="become-a-member" href="/par-mums#kontakti">
                Kļūsti par Biedru
              </a> */}

              <NavLink className="become-a-member" to={'/par-mums#kontakti'}>
                Kļūsti par Biedru
              </NavLink>
              <svg
                width="1063"
                height="709"
                viewBox="0 0 1063 709"
                fill="none"
                className="path-overlay"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="path-main animate"
                  d="M130 176C132.538 184.947 135.707 194.14 136.774 203.391C137.16 206.74 138.237 209.8 139.358 212.969C140.343 215.752 142.005 218.36 143.396 220.966C144.822 223.637 146.403 226.436 148 229"
                  stroke="white"
                  strokeWidth="1"
                  vectorEffect="non-scaling-stroke"
                  strokeMiterlimit="10"
                />
                <path
                  className="path-main animate"
                  d="M165 193C159.701 195.052 154.484 196.989 148.965 198.311C144.958 199.27 140.881 200.032 137.104 201.776C132.012 204.127 127.432 206.736 122.003 208.254C117.204 209.596 112.219 210.106 108 213"
                  stroke="white"
                  strokeWidth="1"
                  vectorEffect="non-scaling-stroke"
                  strokeMiterlimit="10"
                />
                <path
                  className="path-main visible"
                  d="M1062.76 24.5436C884.728 0.625539 696.859 -10.4475 500.182 15.2692C384.525 30.392 261.208 56.6085 136.109 116.412C104.794 131.382 -1.95385 183.734 1.45711 246.642C2.39712 263.978 18.8112 274.61 29.0881 279.382C67.6892 297.306 116.295 300.487 160.214 307.364C301.702 329.518 439.013 358.496 567.426 407.274C692.779 454.891 816.453 510.121 922.662 589.653C929.932 595.097 937.109 600.684 944.006 606.666C947.996 610.126 960.166 618.521 953.936 619.711C939.084 622.549 918.383 606.697 907.542 602.744C900.828 600.296 849.689 572.81 835.977 597.655C826.449 614.92 842.246 636.132 847.075 643.626C856.473 658.21 869.249 667.475 884.407 673.804C890.722 676.441 901.126 676.062 906.135 680.824C911.073 685.52 888.915 690.135 880.927 691.634C842.564 698.832 804.563 703.903 767.642 706.42C714.792 710.023 664.804 706.029 614.43 703.157C595.742 702.091 577.111 700.859 558.129 700.53C549.397 700.379 575.865 700.108 584.697 699.994"
                  stroke="white"
                  strokeWidth="1"
                  vectorEffect="non-scaling-stroke"
                  strokeMiterlimit="10"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
