import React, { Component } from 'react';

export class Intro extends Component {
  render() {
    return (
      <div className="main container noline">
        <div className="wrapper">
          <div className="title">
            <h1 className="huge">
              1<span className="outlined"> no </span>
              100
            </h1>
            <svg
              width="1152"
              height="539"
              viewBox="0 0 1152 539"
              fill="none"
              className="path-overlay"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="path-main animate delayed inverted"
                d="M738.341 496.615C719.047 468.827 702.364 442.479 693.004 409.715C682.597 373.283 678.13 336.131 678.218 298.273C678.3 262.522 680.866 226.677 687.198 191.477C689.257 180.031 691.446 168.532 694.24 157.237C695.554 151.923 696.844 143.981 701.179 139.976C716.559 125.765 736.559 173.618 740.203 181.456C748.76 199.865 755.983 218.8 765.072 236.968C767.478 241.775 771.321 249.763 776.864 251.578C782.332 253.367 775.979 239.656 775.408 238.509C770.977 229.612 765.031 221.526 759.778 213.114C749.003 195.861 738.785 178.252 730.204 159.793C716.83 131.021 705.331 100.764 697.439 69.9999"
                stroke="white"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
                strokeMiterlimit="10"
              />
              <path
                className="path-main animate delayed inverted"
                d="M697.372 446.929C710.637 461.811 722.634 477.694 735.237 493.11C738.691 497.335 741.787 501.958 745.269 506.087C746.504 507.552 742.752 503.184 741.702 501.58C737.184 494.683 734.106 487.716 731.799 479.768C725.989 459.742 722.052 439.561 724.976 418.692"
                stroke="white"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
                strokeMiterlimit="10"
              />
              <path
                className="path-main animate"
                d="M-151.491 485.505C-169.895 501.446 -225.292 547.73 -205.966 536.166C-137.035 494.924 -69.6403 445.325 -0.394114 405.513C176.112 304.035 357.143 229.255 538.048 160.739C695.459 101.121 853.984 50.5854 1013.7 17.82C1039.1 12.6094 1095.75 2.68372 1124.95 1.28834C1133.4 0.884424 1156.28 -0.47761 1149.89 9.8208C1145.31 17.2064 1114.47 31.5102 1112.9 32.2185C1096.85 39.4657 1073.75 44.8174 1051.81 56.4769C998.608 84.7603 974.402 96.472 913.762 126.146"
                stroke="white"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
          <span className="subheading">Kļūsti par vef Rīga līdzīpašnieku</span>
          <div className="side-layout">
            <div className="left">
              <div className="contained-image" />
            </div>
            <div className="right">
              <div className="paragraph-container">
                <span className="subheading">
                  VEF Rīga ir 100 apņēmīgu cilvēku kopums, kuri saredz Latvijas
                  sabiedrības izaugsmi caur basketbola perspektīvu.
                </span>
                <p>
                  Kluba mērķis ir celt basketbola kvalitāti Latvijā, caur kuru
                  iedvesmot Latvijas iedzīvotājus, it īpaši jauniešus,
                  sportiskam un veselīgam dzīvesveidam.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
