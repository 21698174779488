import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class NotFound extends Component {
  render() {
    return (
      <div className="page notfound">
        <div className="wrapper">
          <div className="title">
            <svg
              width="1002"
              height="580"
              viewBox="0 0 1002 580"
              fill="none"
              className="path-overlay"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="path-main animate delayed"
                d="M73.1129 579.305C189.19 480.481 326.104 409.257 457.734 333.808C555.542 277.746 652.272 219.466 748.121 160.122C768.922 147.244 789.731 134.141 809.438 119.614C816.478 114.424 822.488 107.165 830.247 103.04C846.542 94.377 864.775 90.0974 881.035 81.1584C896.734 72.5272 914.263 67.2374 929.733 58.2856C932.825 56.4968 936.457 53.18 937.425 49.9612"
                stroke="white"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
                strokeMiterlimit="10"
              />
              <path
                className="path-main animate"
                d="M57.8682 151.628C136.27 180.362 216.269 204.76 295.413 231.352C343.003 247.342 390.633 263.082 434.278 288.255C457.633 301.725 479.76 315.475 504.81 325.651C517.891 330.965 535.191 335.852 545.639 345.866C555.72 355.528 556.857 363.198 572.416 368.29C608.166 379.988 645.351 388.04 681.228 398.826"
                stroke="white"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
                strokeMiterlimit="10"
              />
            </svg>

            <h1 className="huge">
              <span className="outlined">404</span>
            </h1>
          </div>
          <Link exact className="learn-more-button" to="/">
            Atgriezties sākumlapā
          </Link>
        </div>
      </div>
    );
  }
}
