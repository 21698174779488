import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { Logo } from './assets/logo.js';

import { routes } from '../../routes';

export class Footer extends Component {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {
    window.addEventListener('hashchange', this.scroll);
  }

  scroll() {
    if (window.location.hash === '#kontakti') {
      setTimeout(() => {
        const contacts = document.querySelector('.footer');

        window.scrollTo({
          top: contacts.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }, 200);
    }
  }

  render() {
    return (
      <div id="kontakti" className="footer">
        <div className="wrapper">
          <div className="menu">
            <div className="links">
              {routes.map((route, index) => {
                if (route.title) {
                  return (
                    <NavLink activeClassName="active" to={route.path}>
                      <span className="item">{route.title}</span>
                    </NavLink>
                  );
                }
              })}
              {/* <NavLink activeClassName="active" to="/#biedri">
                <span className="item">Biedri</span>
              </NavLink> */}
            </div>
            <div className="contacts">
              <a href="tel:+37129267060">
                <span className="item">(+371) 292 670 60</span>
              </a>
              <a href="mailto:edgars@vefriga.com">
                <span className="item">edgars@vefriga.com</span>
              </a>
            </div>
            <div className="social">
              <span className="item followus">Seko mums:</span>
              <div className="social-links">
                <a target="_blank" href="https://www.facebook.com/vefriga/">
                  <span className="item">Facebook</span>
                </a>
                <a target="_blank" href="https://www.instagram.com/vefriga/">
                  <span className="item">Instagram</span>
                </a>
                <a target="_blank" href="https://twitter.com/vefriga">
                  <span className="item">Twitter</span>
                </a>
              </div>
            </div>
          </div>
          <div className="footnote">
            <Link exact className="item" to="/">
              <Logo />
            </Link>
            <div className="copy-block">
              <span className="copyright">VEFRiga &copy; 2019</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
