import React, { Component } from 'react';
import VizSensor from 'react-visibility-sensor';

import axios from 'axios';

export class ScheduleMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      company: '',
      email: '',
      name: '',
      sending: false,
      success: false,
      surname: '',
      visible: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    if (window.location.search && window.location.search === '?sent=true') {
      const contact = document.querySelector('.schedule');

      window.scrollTo({
        top: contact.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
      this.setState({ success: true });
    }
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    try {
      const { name, surname, email, company, comment } = this.state;

      if (name === '' || surname === '' || email === '' || company === '')
        return;
      this.setState({ sending: true });

      var form = new FormData();
      form.set('_subject', `${name} ${surname} atsūtīja pieteikumu VEF Rīga`);
      form.set('name', name);
      form.set('surname', surname);
      form.set('email', email);
      form.set('company', company);
      form.set('comment', comment);
      form.set('_confirmation', 'Paldies! Informācija saņemta.');
      form.set(
        '_after',
        'https://www.veseligsieguldijums.lv/par-mums?sent=true'
      );

      const resp = await axios.post(
        'https://mailthis.to/edgars@vefriga.com',
        form,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      window.location.href = 'https://mailthis.to/confirm';
      this.setState({ success: true });
    } catch (error) {
      console.warn({ ...error });
    }
  }
  render() {
    const {
      comment,
      company,
      email,
      name,
      sending,
      success,
      surname,
      visible,
    } = this.state;

    return (
      <div className="schedule container noline">
        <div className="wrapper">
          <div className="title">
            <h1>
              <span className="outlined">Pieteikt</span>
              <br />
              tikšanos
            </h1>
            <span className="subheading">
              Pieraksties uz tikšanos ar VEF Rīga pārstāvi
            </span>
          </div>
          <svg
            width="1339"
            height="975"
            viewBox="0 0 1339 975"
            fill="none"
            class="path-overlay"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className={`path-main${visible ? ' animate' : ''}`}
              d="M1.0002 541.948C72.8967 491.71 145.043 444.505 219.859 406.166C325.087 352.244 432.782 302.869 540.28 271.477C685.69 229.013 864.029 196.016 970.874 308.893C998.52 338.1 1015.5 380.223 990.312 433.23C968.982 478.113 925.694 506.456 891.477 529.998C849.879 558.62 807.411 584.782 765.102 610.734C732.256 630.881 697.909 654.629 663.992 669.222C642.842 678.322 624.369 679.517 605.543 672.644C597.284 669.628 606.56 662.485 611.902 663.985C620.433 666.382 630.398 664.956 639.299 666.303C675.92 671.842 713.316 671.697 751.166 670.792C813.941 669.291 877.78 660.262 939.913 663.215C986.842 665.445 1032.57 673.923 1080.27 671.398C1096.6 670.533 1112.61 671.714 1128.92 670.981C1139.45 670.508 1150.15 667.77 1160.67 667.204C1180.94 666.113 1200.11 670.451 1220.33 669.222C1240.3 668.009 1259.28 668.365 1278.74 670.295C1281.04 670.524 1301.6 674.25 1292.06 670.769"
              stroke="white"
              strokeWidth="1"
              vectorEffect="non-scaling-stroke"
              strokeMiterlimit="10"
            />
            <VizSensor
              onChange={(isVisible) => {
                if (isVisible) {
                  this.setState({ visible: true });
                }
              }}
            >
              <path
                className={`path-main delayed${visible ? ' animate' : ''}`}
                d="M1191.29 615.482C1211.9 631.641 1233.31 643.713 1257.28 654.061C1263.63 656.805 1270.05 659.438 1276.28 662.464C1280.14 664.341 1283.92 666.82 1288.12 667.907C1290.32 668.476 1307.11 671.764 1304.5 672.586C1288.16 677.721 1270.75 681.408 1254.12 685.572C1223.41 693.263 1192.59 700.655 1162.31 709.97C1142.86 715.954 1124.59 724.297 1104.49 728.066"
                stroke="white"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
                strokeMiterlimit="10"
              />
            </VizSensor>
          </svg>
          {(success && (
            <div className="loader">
              <h3>Paldies, Tavs pieteikums ir saņemts</h3>
              <p className="subheading">
                Apskati savu epastu, lai uzzinātu informāciju par nākamajiem
                soļiem.
              </p>
            </div>
          )) || (
            <React.Fragment>
              {(!sending && (
                <form
                  className="contact"
                  onSubmit={(event) => this.handleSubmit(event)}
                >
                  <div className="row">
                    <label className="required" for="name">
                      <input
                        type="text"
                        name="name" //entry.19927942
                        placeholder="Vārds"
                        required="required"
                        value={name}
                        onChange={this.handleChange}
                      />
                    </label>
                    <label className="required" for="surname">
                      <input
                        type="text"
                        name="surname" //entry.1832985257
                        placeholder="Uzvārds"
                        required="required"
                        value={surname}
                        onChange={this.handleChange}
                      />
                    </label>
                  </div>
                  <div className="row">
                    <label className="required" for="company">
                      <input
                        type="text"
                        name="company" //entry.737404369
                        placeholder="Uzņēmums"
                        required="required"
                        value={company}
                        onChange={this.handleChange}
                      />
                    </label>
                    <label className="required" for="email">
                      <input
                        type="email"
                        name="email" //emailAddress
                        placeholder="E-pasts"
                        required="required"
                        value={email}
                        onChange={this.handleChange}
                      />
                    </label>
                  </div>
                  <textarea
                    name="comment" //entry.165147774
                    placeholder="Jautājumi, ieteikumi un atsauksmes"
                    value={comment}
                    onChange={this.handleChange}
                  ></textarea>
                  <span className="footnote">
                    * Šīs ailes aizpildāmas obligāti
                  </span>
                  <input type="submit" value="Nosūtīt pieteikumu" />
                </form>
              )) || (
                <div className="loader">
                  <svg
                    className="animatedLoader"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                  >
                    <path
                      stroke="none"
                      d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
                      fill="#FFFFFF"
                      transform="rotate(323.915 50 51)"
                    ></path>
                  </svg>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
