import React, { Component } from 'react';
import VizSensor from 'react-visibility-sensor';

import Video from './assets/landing-hoops.jpg';

export class WhoWeAre extends Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      videoOverlay: false,
    };
  }
  _showVideo() {
    const video = document.querySelector('.video-overlay');
    video.classList.add('visible');
  }
  render() {
    const { visible } = this.state;
    return (
      <React.Fragment>
        <div className="container whoweare noline">
          <div className="wrapper">
            <div className="side-layout">
              <div className="left">
                <h2>
                  Kas
                  <br />
                  <span className="outlined">
                    ir VEF
                    <br />
                    Rīga
                  </span>
                </h2>
              </div>
              <div className="right">
                <p>
                  Leģendārā VEF basketbola komanda radās, satiekoties Spartaks
                  basketbola komandai un rūpnīcas VEF sporta dzīves vadītājam
                  Arnoldam Brambergam. Neatlaidīgs darbs un stingra mērķtiecība
                  ir tā, kas VEF basketbolistus padarījusi par izaicinošiem un
                  cieņpilniem pretiniekiem.
                </p>
                <span className="subheading">
                  VEF basketbola komanda spilgti un nepārprotami simbolizē
                  latvisko identitāti, kā arī nes Latvijas vārdu pasaulē.
                </span>
              </div>
            </div>
          </div>
          <VizSensor
            onChange={(isVisible) => {
              if (isVisible) {
                this.setState({ visible: true });
              }
            }}
          >
            <div className="video" style={{ backgroundImage: `url(${Video})` }}>
              {/* <a
                className="learn-more-button"
                onClick={() => {
                  this._showVideo();
                }}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  className="inline"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 9L-8.15666e-07 17.6603L-5.85622e-08 0.339746L18 9Z"
                    fill="white"
                  />
                </svg>
                Skatīt video
              </a> */}
              <svg
                className="path-overlay"
                width="1440"
                height="962"
                viewBox="0 0 1440 962"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className={`path-main${visible ? ' animate' : ''}`}
                  d="M-28 781.587C79.3623 860.096 220.117 887.392 351.572 866.428C483.028 845.464 604.902 778.914 702.261 688.165C756.224 637.936 804.269 578.421 826.249 508.072C833.435 485.42 837.662 461.08 833.153 437.724C826.108 401.987 798.352 372.722 765.523 356.964C732.694 341.206 695.357 337.266 658.865 338.11C473.306 342.331 308.74 461.783 186.302 601.073C148.683 643.986 113.459 689.431 84.5755 738.675C63.8638 774.131 45.8292 815.496 56.6782 855.172C64.1456 882.749 84.9981 905.26 108.669 921.44C156.714 954.223 217.44 964.775 275.489 959.851C333.538 954.786 389.191 935.369 442.591 912.295C778.626 767.377 1034.07 485.139 1258.66 196.569C1316.57 122 1376.16 44.194 1460 1C1468.17 15.4918 1469.3 33.7824 1462.81 49.1184C1462.81 49.1184 1160.17 462.205 1339.25 655.523"
                  stroke="white"
                  strokeWidth="1"
                  vectorEffect="non-scaling-stroke"
                  strokeMiterlimit="10"
                />
              </svg>
            </div>
          </VizSensor>
        </div>
      </React.Fragment>
    );
  }
}
