import React, { Component } from 'react';

import kvk from './assets/kvsk.png';

export class Machup extends Component {
  render() {
    return (
      <div className="machup container noline">
        <div className="wrapper padded left">
          <h3>
            Krastu
            <br />
            <span className="outlined">mačs</span>
          </h3>
          <div className="paragraph-container">
            <p>
              Basketbola kluba "VEF Rīga" rīkotais "Krastu mačs" ir diennakts
              basketbola maratons pašā Rīgas centrā – laukumā pie Latvijas
              Nacionālās operas. Tā dalībnieki ir divas komandas – Daugavas labā
              un kreisā krasta iedzīvotāji.
            </p>
            <span className="subheading">
              Tā dalībnieki ir divas komandas – Daugavas labā un kreisā krasta
              iedzīvotāji.
            </span>
            {/* <img className="icon" src={kvk} /> */}
            <svg
              className="icon"
              viewBox="0 0 145 144"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M68.4228 9.60508L64.4836 9.92885L63.6742 12.8967L60.4365 13.1665L63.944 0.269806L67.2356 0L72.9015 12.0873L69.7178 12.3571L68.4228 9.60508ZM67.3975 7.06891L65.8326 3.07578L65.0772 7.28475L67.3975 7.06891Z"
                fill="white"
              />
              <path
                d="M39.1756 22.2306L37.1251 18.9929L41.9276 20.5038L45.4351 18.2374L37.1251 16.025L37.7726 8.30859L34.5889 10.3591L34.3731 14.676L32.4844 11.6542L29.9482 13.327L36.6394 23.8494L39.1756 22.2306Z"
                fill="white"
              />
              <path
                d="M66.5879 100.637H76.5167V103.443H73.1172V113.749H69.9335V103.443H66.5879V100.637Z"
                fill="white"
              />
              <path
                d="M105.17 120.174L107.76 118.555L111.969 125.246C113.534 127.674 112.563 130.48 110.351 131.883C108.192 133.286 105.224 132.962 103.659 130.534L99.3965 123.843L101.987 122.224L106.25 128.915C106.843 129.833 108.03 129.94 108.84 129.455C109.649 128.969 110.027 127.836 109.433 126.865L105.17 120.174Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M73.3872 61.3533C75.4917 60.8136 76.517 59.0329 76.517 57.1443C76.517 54.9858 75.114 52.7734 72.0382 52.7734H66.6421V65.832H69.8258V61.4612H70.0956L72.9016 65.832H76.5709L73.3872 61.3533ZM71.8763 58.8171H69.8258V55.6334H71.9303C72.9555 55.6334 73.3333 56.4428 73.3333 57.2522C73.3333 57.9537 72.9555 58.8171 71.8763 58.8171Z"
                fill="white"
              />
              <path
                d="M103.875 22.1236C101.555 20.6127 100.853 18.3464 102.148 16.3498L104.685 18.0226C104.361 18.6162 104.469 19.3177 105.332 19.9112C105.98 20.3429 106.789 20.4509 107.221 19.8033C107.329 19.6414 107.545 19.2637 106.735 18.2384L105.764 16.9973C103.875 14.8389 104.415 13.1121 105.062 12.1408C106.357 10.2522 108.732 9.9824 110.836 11.3314C112.887 12.6805 113.642 14.8929 112.293 16.9434L109.757 15.2706C110.081 14.731 110.027 14.0834 109.271 13.5978C108.57 13.1661 107.922 13.2201 107.599 13.7057C107.491 13.9216 107.275 14.3532 108.03 15.2166L109.002 16.4038C111.214 19.0479 110.297 20.7207 109.811 21.4761C108.3 23.7964 105.656 23.2568 103.875 22.1236Z"
                fill="white"
              />
              <path
                d="M75.7072 79.4864V76.7344H67.2354V89.793H75.8691V87.0409H70.4191V84.7206H75.0597V82.0226H70.4191V79.4864H75.7072Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M126.647 23.4195C137.709 36.1004 144.4 52.6665 144.4 70.7974C144.4 88.9284 137.709 105.494 126.647 118.175C125.784 119.255 124.812 120.28 123.841 121.251C122.87 120.226 121.952 119.201 121.035 118.175C110.405 105.98 103.821 90.1695 103.336 72.8479C103.282 72.2004 103.282 71.4989 103.282 70.7974C103.282 70.4467 103.295 70.1094 103.309 69.7721C103.322 69.4351 103.336 69.0974 103.336 68.7469C103.821 51.4254 110.405 35.6147 121.035 23.4195C121.898 22.3403 122.87 21.3151 123.841 20.3438C124.812 21.3685 125.729 22.3933 126.646 23.418L126.647 23.4195ZM140.191 68.7469C139.706 52.6125 133.608 37.9351 123.787 26.4953C113.966 37.9351 107.868 52.6665 107.383 68.7469H140.191ZM107.383 72.8479C107.868 88.9823 113.966 103.66 123.787 115.099C133.608 103.714 139.706 88.9823 140.191 72.8479H107.383Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M72.524 28.9219H67.1279V41.9805H70.3116V37.6096H72.524C75.5998 37.6096 77.0028 35.4512 77.0028 33.2927C77.0028 31.0803 75.5998 28.9219 72.524 28.9219ZM72.3622 34.8576H70.2577V31.6739H72.3622C73.3874 31.6739 73.7652 32.4833 73.7652 33.2927C73.8191 34.0482 73.4414 34.8576 72.3622 34.8576Z"
                fill="white"
              />
              <path
                d="M77.2722 3.61479C77.5959 1.34842 79.6465 0.107317 82.1287 0.485045C84.5569 0.808812 86.1758 2.48161 85.9059 4.96382L82.8841 4.53213C82.992 3.8846 82.6683 3.34499 81.7509 3.1831C80.9415 3.07518 80.4019 3.39895 80.294 3.99252C80.294 4.26232 80.294 4.69401 81.3192 5.17966L82.6683 5.8272C85.7441 7.28415 85.6361 9.22675 85.5282 10.0901C85.1505 12.7882 82.5064 13.4897 80.4019 13.1659C77.6499 12.7342 76.085 10.9535 76.4088 8.63318L79.4306 9.06487C79.3766 9.7124 79.7544 10.306 80.7796 10.4679C81.5351 10.6297 82.3445 10.3599 82.4524 9.60448C82.5064 9.44259 82.5603 9.01091 81.3732 8.41733L79.9702 7.71584C77.3261 6.58265 77.1103 4.74797 77.2722 3.61479Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M54.8784 6.04458C54.2308 4.04802 52.2882 2.53711 49.4822 3.45445L44.5718 5.12724L48.6189 16.9447L51.4788 15.9734L50.1298 12.0343L50.3996 11.9263L54.2848 15.0021L57.6304 13.8689L53.3674 10.7392C55.0942 9.65998 55.4719 7.71738 54.8784 6.04458ZM51.209 8.90452L49.3204 9.55206L48.3491 6.63816L50.2377 5.99062C51.155 5.66686 51.8026 6.31439 52.0184 7.01588C52.2343 7.71738 52.1263 8.58076 51.209 8.90452Z"
                fill="white"
              />
              <path
                d="M92.7592 2.85938L101.663 6.25893L100.691 8.74114L97.6697 7.60795L94.1622 16.7813L91.3022 15.7021L94.8637 6.52873L91.8419 5.34159L92.7592 2.85938Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M64.2677 129.992L58.4399 141.972L61.6776 142.295L63.0266 139.543L66.9658 139.921L67.7213 142.889L70.905 143.213L67.5594 130.316L64.2677 129.992ZM64.0519 137.061L65.5628 133.068L66.3722 137.277L64.0519 137.061Z"
                fill="white"
              />
              <path
                d="M79.6465 134.524L81.1034 134.794C84.503 135.388 84.9347 137.276 85.0965 138.14C85.4743 140.892 83.1 142.241 80.9955 142.511C78.2435 142.888 76.2469 141.593 75.9231 139.273L78.945 138.895C79.0529 139.597 79.5925 140.082 80.6178 139.92C81.4272 139.813 82.1287 139.381 82.0208 138.625C82.0208 138.41 81.9668 138.032 80.6717 137.762L79.1608 137.438C76.3009 137.061 75.5994 135.334 75.4375 134.201C75.1137 131.934 76.7865 130.153 79.2687 129.83C81.697 129.506 83.6936 130.693 84.0173 133.121L80.9955 133.499C80.8876 132.852 80.4559 132.42 79.5385 132.528C78.7291 132.582 78.2974 133.067 78.3514 133.661L78.3533 133.669C78.4081 133.888 78.5267 134.364 79.6465 134.524Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M53.044 127.403L48.1875 125.73L44.0864 137.548L46.9464 138.519L48.2954 134.58L48.5652 134.688L49.7523 139.491L53.0979 140.624L51.641 135.551C53.7455 135.713 55.2024 134.418 55.796 132.745C56.3896 130.803 55.85 128.375 53.044 127.403ZM52.8281 131.774C52.5583 132.476 51.9647 133.123 51.0474 132.799L49.1588 132.152L50.184 129.238L52.0727 129.885C52.99 130.209 53.0979 131.073 52.8281 131.774Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M41.1184 68.7469C41.1723 69.3944 41.1723 70.0959 41.1723 70.7974C41.1723 71.4989 41.1723 72.2004 41.1184 72.8479C40.6327 90.1695 34.0495 105.98 23.4191 118.175C22.5018 119.255 21.5305 120.28 20.5592 121.251C19.5879 120.226 18.6706 119.201 17.7532 118.175C6.69118 105.494 0 88.9284 0 70.7974C0 52.6665 6.69118 36.1004 17.7532 23.4195C18.6166 22.3403 19.5879 21.3151 20.5592 20.3438C21.5844 21.369 22.5017 22.3942 23.419 23.4194C34.0494 35.6147 40.6327 51.4254 41.1184 68.7469ZM4.20897 72.8479C4.69462 88.9823 10.7922 103.66 20.6132 115.099C30.4341 103.714 36.5317 88.9823 37.0173 72.8479H4.20897ZM20.6132 26.4953C10.7922 37.9351 4.69462 52.6665 4.20897 68.7469H37.0173C36.5317 52.6665 30.4341 37.9351 20.6132 26.4953Z"
                fill="white"
              />
              <path
                d="M44.3022 123.894L41.1185 121.898L37.0714 123.57L39.014 120.549L36.4238 118.93L29.7866 129.506L32.3228 131.125L34.3733 127.887L35.0748 132.852L38.5823 135.118L37.0714 126.646L44.3022 123.894Z"
                fill="white"
              />
              <path
                d="M95.9966 125.516L96.86 127.998L93.8382 129.077L97.0758 138.358L94.2159 139.384L90.9782 130.048L87.9024 131.128L87.0391 128.645L95.9966 125.516Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
