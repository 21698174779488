import React from 'react';

export const Logo = () => {
  return (
    <svg
      width="60"
      height="19"
      viewBox="0 0 60 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.7137 4.30939H38.8749L39.5229 0H22.1798L18.6087 18.8348H36.6449L37.2928 14.4952H28.1467L28.674 11.0899H37.3983L38.0311 7.0668H29.2918L29.7137 4.30939ZM25.2687 17.7951H24.2742L27.4535 1.03968H27.77L25.2687 17.7951ZM15.8513 0L10.442 12.9884L8.13661 0H0L4.33953 18.8348H13.0035L20.9292 0H15.8513ZM8.64892 17.7951L4.73129 1.03968H5.77097L8.93521 17.7951H8.64892ZM59.2014 4.30939L60 0H41.6323L38.7092 18.8348H46.9211L48.3827 11.0899H56.6851L57.4535 7.0668H49.1361L49.6635 4.30939H59.2014ZM44.6007 17.7951H44.2843L46.7855 1.03968H47.78L44.6007 17.7951Z"
        fill="#999999"
      />
    </svg>
  );
};
