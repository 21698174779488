import React from 'react';

export const Logo = () => {
  return (
    <svg
      width="100"
      height="32"
      viewBox="0 0 100 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.5229 7.18232H64.7916L65.8714 0H36.9663L31.0146 31.3913H61.0748L62.1547 24.1587H46.9111L47.7901 18.4832H62.3305L63.3852 11.778H48.8197L49.5229 7.18232ZM42.1145 29.6585H40.4571L45.7559 1.7328H46.2833L42.1145 29.6585ZM26.4189 0L17.4033 21.6474L13.561 0H0L7.23255 31.3913H21.6725L34.882 0H26.4189ZM14.4149 29.6585L7.88548 1.7328H9.61828L14.892 29.6585H14.4149ZM98.669 7.18232L100 0H69.3872L64.5153 31.3913H78.2019L80.6379 18.4832H94.4751L95.7559 11.778H81.8935L82.7725 7.18232H98.669ZM74.3345 29.6585H73.8071L77.9759 1.7328H79.6333L74.3345 29.6585Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};
