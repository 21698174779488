import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { Logo } from './assets/logo.js';

import { routes } from '../../routes';

export class Header extends Component {
  constructor() {
    super();

    this.state = {
      menuVisible: false,
    };
  }

  componentDidMount() {}

  _toggleMobileMenu() {
    this.setState({ menuVisible: !this.state.menuVisible });
  }

  _hideMenu() {
    this.setState({ menuVisible: false });
  }

  render() {
    const { menuVisible } = this.state;
    return (
      <div onClick={() => menuVisible && this._hideMenu()} className="header">
        <div className="wrapper">
          <Link exact className="item" to="/">
            <Logo />
          </Link>
          <div className="mobile menu-container">
            <div onClick={() => this._toggleMobileMenu()}>
              {(menuVisible && (
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0.5 20L19.5 1" stroke="#CCCCCC" />
                  <path d="M19.5 20L0.5 1" stroke="#CCCCCC" />
                </svg>
              )) || (
                <svg
                  className="icon"
                  width="24"
                  height="20"
                  viewBox="0 0 24 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="24"
                    y1="19.5"
                    x2="-4.99934e-08"
                    y2="19.5"
                    stroke="#CCCCCC"
                    strokeWidth="1.25"
                    vectorEffect="non-scaling-stroke"
                    strokeMiterlimit="10"
                  />
                  <line
                    x1="24"
                    y1="10"
                    x2="-4.99934e-08"
                    y2="10"
                    stroke="#CCCCCC"
                    strokeWidth="1.25"
                    vectorEffect="non-scaling-stroke"
                    strokeMiterlimit="10"
                  />
                  <line
                    x1="24"
                    y1="0.5"
                    x2="-3.51138e-08"
                    y2="0.499997"
                    stroke="#CCCCCC"
                    strokeWidth="1.25"
                    vectorEffect="non-scaling-stroke"
                    strokeMiterlimit="10"
                  />
                </svg>
              )}
            </div>
            <div className={`mobile-menu${menuVisible ? ' visible' : ''}`}>
              <div className="inner">
                {routes.map((route, index) => {
                  if (route.title) {
                    return (
                      <NavLink activeClassName="active" to={route.path}>
                        <span className="item">{route.title}</span>
                      </NavLink>
                    );
                  }
                })}
                {/* <NavLink activeClassName="active" to="/#biedri">
                  <span className="item">Biedri</span>
                </NavLink> */}
                <a href="#kontakti">
                  <span className="item">Kontakti</span>
                </a>
                <div className="social-links">
                  <span className="item followus">Seko mums</span>
                  <a target="_blank" href="https://www.facebook.com/vefriga/">
                    <span className="item">Facebook</span>
                  </a>
                  <a target="_blank" href="https://www.instagram.com/vefriga/">
                    <span className="item">Instagram</span>
                  </a>
                  <a target="_blank" href="https://twitter.com/vefriga">
                    <span className="item">Twitter</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="menu">
            {routes.map((route, index) => {
              if (route.title) {
                return (
                  <NavLink activeClassName="active" to={route.path}>
                    <span className="item">{route.title}</span>
                  </NavLink>
                );
              }
            })}
            {/* <NavLink activeClassName="active" to="/#biedri">
              <span className="item">Biedri</span>
            </NavLink> */}
            <a href="#kontakti">
              <span className="item">Kontakti</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
