import React, { Component } from 'react';

export class Intro extends Component {
  render() {
    return (
      <div className="main container">
        <div className="title">
          <h1>
            <span className="outlined">VEF</span>
            <br />
            labie darbi
          </h1>
          <svg
            className="path-overlay"
            width="1309"
            height="616"
            viewBox="0 0 1309 616"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="path-main animate"
              d="M54.0801 614.844C148.168 466.678 307.658 412.912 450.525 382.066C631.117 343.075 815.56 337.95 998.029 314.656C1034.89 309.95 986.747 303.825 981.092 302.859C928.299 293.834 875.412 286.076 822.575 277.957C649.164 251.308 475.412 226.708 302.311 194.171C216.282 178.001 129.471 162.644 44.0665 138.836C28.5551 134.511 50.8138 142.056 51.7763 142.492C94.6882 161.939 137.186 179.431 181.245 193.126C256.121 216.401 331.651 233.649 407.326 249.145C536.597 275.618 666.136 299.821 795.716 321.396C899.06 338.603 1002.8 354.167 1106.39 357.019C1146.16 358.114 1175.76 357.553 1214.53 353.93C1242.41 351.325 1266.24 343.537 1293.02 337.012"
              stroke="white"
              strokeWidth="1"
              vectorEffect="non-scaling-stroke"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
        <span className="subheading">Vairāk nekā basketbola komanda</span>
      </div>
    );
  }
}
