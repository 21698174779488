import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import luxy from 'luxy.js';

/* webpackPrefetch: true */
import './styles/reset.css';
import './assets/fonts/Syne/Syne.css';
import './assets/fonts/SharpGrotesk/SharpGrotesk.css';
import './styles/index.scss';

import './views/About/containers/styles.scss';
import './views/Work/containers/styles.scss';
import './views/Home/containers/styles.scss';

import './styles/mobile.scss';

/* Components */
import { Header } from './components/Header';
import { Footer } from './components/Footer';

/* Views */
import Home from './views/Home';
import About from './views/About';
import Work from './views/Work';
import NotFound from './views/NotFound';

const mobile = require('is-mobile');

export const routes = [
  { component: Home, path: '/' },
  { component: About, path: '/par-mums', title: 'Par VEF Rīga' },
  { component: Work, path: '/labie-darbi', title: 'Labie darbi' },
];

export default class App extends Component {
  constructor() {
    super();
    this.isMobile = mobile();
  }
  componentDidMount() {
    !this.isMobile &&
      luxy.init({
        wrapper: '#vef',
        wrapperSpeed: 0.15,
      });
  }
  _hideVideo() {
    const video = document.querySelector('.video-overlay');
    video.classList.remove('visible');
  }
  render() {
    return (
      <React.Fragment>
        <div
          className="video-overlay"
          onClick={() => {
            this._hideVideo();
          }}
        >
          <div className="video-block">vide-yooo</div>
        </div>
        <Router>
          {(this.isMobile && <Header />) || null}
          <div id="vef">
            {(!this.isMobile && <Header />) || null}
            <Switch>
              {routes.map((route, index) => {
                return <Route key={index} exact {...route} />;
              })}
              <Route component={NotFound} />
            </Switch>
            <Footer />
          </div>
        </Router>
      </React.Fragment>
    );
  }
}
