import React, { Component } from 'react';
import VizSensor from 'react-visibility-sensor';

export class Payment extends Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      visibleMember: false,
    };
  }
  render() {
    const { visible, visibleMember } = this.state;
    return (
      <div className="payment container">
        <div className="wrapper">
          <h3>Ne vairāk, ne mazāk</h3>
          <div className="title">
            <h2 className="huge">
              <span className="outlined">€ 10'000</span>
            </h2>
            <svg
              width="1134"
              height="411"
              viewBox="0 0 1134 411"
              fill="none"
              className="path-overlay"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={`path-main${visible ? ' animate' : ''}`}
                d="M932.188 36.8672C924.392 29.838 914.997 20.6975 905.966 14.9783C894.728 7.86137 882.7 5.83469 869.744 4.53383C835.916 1.13745 801.587 1.33928 767.633 1.08939C661.431 0.307777 555.339 4.53141 449.966 18.5338C349.241 31.9187 246.81 49.1212 151.299 84.8672C122.995 95.4605 95.5299 107.883 70.4661 124.923C53.7149 136.311 38.3503 149.569 28.2994 167.423C13.3208 194.029 23.839 218.52 44.855 238.089C84.5625 275.064 141.767 287.046 193.522 295.312C237.185 302.285 281.551 304.342 325.688 305.701C387.993 307.618 450.532 306.712 512.744 302.756C647.369 294.196 782.255 268.404 912.744 234.867C968.683 220.49 1026.14 206.408 1079.13 182.923C1091.39 177.49 1100.94 172.871 1111.97 165.534C1120.41 159.914 1131.25 152.021 1132.41 140.756C1134.32 122.221 1113.07 108.698 1100.02 100.312C1076.66 85.3028 1051.16 73.8822 1024.85 65.0894C938.646 36.2694 845.968 23.2557 755.299 22.5338C648.723 21.6853 541.848 31.5614 436.633 48.3116C346.285 62.6949 254.709 81.2445 169.411 115.089C134.533 128.928 100.893 145.273 68.5216 164.256C47.4884 176.59 24.3337 189.885 8.96608 209.423C-6.67972 229.314 2.6265 250.952 20.4105 265.867C38.8387 281.323 63.5619 279.424 86.1883 279.867"
                stroke="white"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
                strokeMiterlimit="10"
              />
              <path
                className={`path-main delayed${
                  visible ? ' animate' : ''
                } hidden-mobile`}
                d="M460 120C322.772 178.651 208.136 276.2 97 374"
                stroke="white"
                stroke="white"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
                strokeMiterlimit="10"
              />
              <path
                className={`path-main delayed${
                  visible ? ' animate' : ''
                } hidden-mobile`}
                d="M159 285C132.347 320.718 105.006 355.658 82.8889 394.444C81.9703 396.056 72.7431 411.42 73.0556 409.389C74.2332 401.734 86.0587 393.722 91.3334 389.556C105.357 378.478 120.494 368.854 135.556 359.278C165.67 340.132 195.881 321.138 226 302"
                stroke="white"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
          <VizSensor
            onChange={(isVisible) => {
              if (isVisible) {
                this.setState({ visible: true });
              }
            }}
          >
            <span className="subheading">Sezonā</span>
          </VizSensor>
          <div className="steps">
            <div className="row">
              <div className="column">
                <div className="paragraph-container">
                  <h3>01</h3>
                  <span className="subheading">Pieteikums</span>
                  <p>
                    Pieteikties uz tikšanos ar VEF Rīga vadību var, aizpildot
                    pieteikšnās formu vai, zvanot un rakstot uz lapā norādīto
                    kontaktinformāciju.
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="paragraph-container">
                  <h3>02</h3>
                  <span className="subheading">Tikšanās</span>
                  <p>
                    Tikšanās laikā izrunāsim visas ar dalību klubā saistītās
                    nianses, sākot ar mūsu kopīgajām vērtībām, beidzot ar
                    reāliem ieteikumiem kluba darbam.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="column">
                <div className="paragraph-container">
                  <h3>03</h3>
                  <span className="subheading">Dalības maksa</span>
                  <p>
                    Veiksmīgu sarunu rezultātā, vienosimies par Jūsu kā VEF
                    biedra uzņemšanu. Par oficiālu biedru jūs
                    kļūstat pēc biedra naudas iemaksas.
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="paragraph-container">
                  <svg
                    width="502"
                    height="333"
                    viewBox="0 0 502 333"
                    fill="none"
                    className="path-overlay"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className={`path-main${visibleMember ? ' animate' : ''}`}
                      d="M501 109C459.47 75.7765 423.968 43.8519 370 38C337.405 34.4657 304.787 33.1102 272.333 38.5556C246.322 42.9198 221.052 49.5103 195.111 54C169.255 58.475 146.939 65.6809 124.555 79.1111C87.1536 101.552 39.2781 123.394 16.9996 163C2.01628 189.637 -6.61214 237.868 10.9996 265C22.1903 282.24 37.0669 297.259 53.1107 310C72.1919 325.153 99.475 327.466 123 329.444C177.153 333.999 227.607 335.326 280 318.889C336.476 301.171 386.017 273.982 428 232C444.746 215.253 463 185.224 463 161C463 156 463 151 463 146C463 139.173 457.267 132.676 457 125.444C456.595 114.51 439.833 92.0385 430.889 85.8889C424.01 81.1596 418.542 74.3205 412.555 68.5556C407.262 63.458 399.459 61.5708 393.111 58C378.223 49.6259 362.566 43.0078 348.555 33C340.851 27.4967 333.955 21.9806 325.111 18.1111C316.758 14.4567 307.88 16.4401 299 12C292.165 8.58291 285.357 9.25611 278.111 7.44444C270.916 5.64574 263.93 3.31025 257 1"
                      stroke="white"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                  </svg>

                  <h3>04</h3>
                  <span className="subheading">Biedrs</span>
                  <VizSensor
                    onChange={(isVisible) => {
                      if (isVisible) {
                        this.setState({ visibleMember: true });
                      }
                    }}
                  >
                    <p>
                      Jūs esat kļuvis/usi par VEF Rīga biedru. Jums ir iespēja
                      baudīt visas VEF Rīga biedra privilēģijas un iesaistīties
                      ar VEF Rīga saistītu jautājumu risināšanā.
                    </p>
                  </VizSensor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
