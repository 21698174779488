import React, { Component } from 'react';
import luxy from 'luxy.js';

import { Intro } from './containers/intro';
import { School } from './containers/school';
import { Machup } from './containers/machup';
import { League } from './containers/league';
import { SocialProjects } from './containers/social-projects';

export default class Work extends Component {
  componentDidMount() {
    window.location.hash !== '#kontakti' && window.scrollTo(0, 0);
    luxy.resize();
  }
  render() {
    return (
      <div className="page work">
        <Intro />
        <School />
        <Machup />
        <League />
        <SocialProjects />
      </div>
    );
  }
}
