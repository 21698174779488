import React, { Component } from 'react';
import VizSensor from 'react-visibility-sensor';

import join from './assets/landing-piedalies.jpg';
import jauniesi from './assets/jauniesi.png';
import events from './assets/events.png';
import { NavLink } from 'react-router-dom';

export class Privilegies extends Component {
  constructor() {
    super();
    this.state = {
      visible: false,
    };
  }
  render() {
    const { visible } = this.state;
    return (
      <div className="container noline member-privilegies">
        <div className="wrapper">
          <h2 className="cutaway">
            <span className="outlined">Biedru</span>
            <br />
            privilēģijas
          </h2>
          <div className="privilegies">
            <div className="item">
              <img src={join} />
              <span className="subheading">
                Dalība ar klubu saistītu lēmumu pieņemšanā
              </span>
            </div>
            <div className="item">
              <img src={jauniesi} />
              <span className="subheading">
                Veselīga dzīvesveida un jauniešu izglītības atbalsts
              </span>
            </div>
            <div className="item">
              <VizSensor
                onChange={(isVisible) => {
                  if (isVisible) {
                    this.setState({ visible: true });
                  }
                }}
              >
                <img src={events} />
              </VizSensor>
              <svg
                className="path-overlay"
                width="415"
                height="273"
                viewBox="0 0 415 273"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className={`path-main${visible ? ' animate' : ''}`}
                  d="M1.00009 244.738C1.00009 244.738 7.36835 233.729 8.93821 231.293C26.2156 204.374 46.9125 179.477 66.3615 154.237C92.6607 120.186 119.431 86.6777 147.051 53.7376C154.678 44.7259 162.314 35.5875 170.068 26.5846C174.418 13.5244 180.669 9.63494 188.964 14.6716C186.293 40.0691 178.295 63.4383 172.849 88.5138C166.27 118.856 160.585 149.134 161.329 180.244C166.05 181.848 169.474 180.178 171.348 175.218C177.532 168.651 182.875 161.389 187.493 153.566C191.852 147.635 196.337 141.712 200.687 135.907C205.612 127.342 212.72 122.113 221.766 120.074C225.452 125.551 226.933 131.638 226.074 138.451C225.941 147.606 227.877 156.27 231.892 164.316C242.064 173.43 255.591 169.16 267.777 169.505C285.929 170.014 297.429 178.331 313.89 184.831C328.74 190.709 344.013 194.198 359.951 195.443C374.769 196.355 389.157 194.309 403.226 189.567"
                  stroke="white"
                  strokeWidth="1"
                  vectorEffect="non-scaling-stroke"
                  strokeMiterlimit="10"
                />
              </svg>

              <span className="subheading">
                Īpaša zona spēlēs un pasākumi tikai biedriem
              </span>
            </div>
          </div>
          <NavLink className="learn-more-button" to="/par-mums">
            Uzzini vairāk
          </NavLink>
        </div>
      </div>
    );
  }
}
