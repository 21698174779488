import React, { Component } from 'react';

export class WhyJoin extends Component {
  render() {
    return (
      <div className="whyjoin container noline">
        <div className="wrapper">
          <div className="title">
            <h2 className="cutaway">
              <span className="outlined">Kādēļ kļūt</span>
              <br />
              par biedru
            </h2>
            <span className="subheading">Kādēļ kļūt par VEF Rīga biedru?</span>
          </div>
          <div className="boxmodel">
            <div className="row">
              <div className="block">
                <div className="inner">
                  <svg
                    width="55"
                    height="62"
                    viewBox="0 0 55 62"
                    fill="none"
                    className="icon"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4149 33.6424C10.7932 31.0777 11.4926 28.0466 12.2699 25.5596C13.5134 21.7513 17.4771 13.5907 21.8294 12.2694C26.6481 10.7927 22.7621 25.3264 22.5289 27.9689C22.7621 27.7357 22.9175 27.1917 23.1507 26.8808C26.1817 22.1399 28.4356 17.7098 32.6325 13.6684C36.2076 10.2487 40.2491 6.90673 44.2905 3.95336C45.9227 2.78756 48.1765 1.31088 50.275 1C48.0211 4.65284 45.6895 8.53885 43.2802 12.1917C40.8708 16 37.9952 19.4197 35.3527 23.1502C34.1869 24.7824 33.4874 26.6476 32.3216 28.2798C34.4201 25.8704 37.4512 24.316 39.9382 22.373C40.7931 21.7513 45.7672 16.3109 47.4771 17.7098C50.0418 19.8083 35.1196 39.0051 32.3994 41.4922C32.6325 41.259 32.788 40.7927 32.9434 40.4818C25.1714 41.7253 36.5962 27.3471 37.762 25.9482"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M37.0625 39.6269C36.0522 40.4818 35.9744 42.3471 36.2853 43.4352C40.1713 44.1346 44.99 34.031 46.5444 31.4663C47.1661 30.4559 50.5858 24.1606 49.4977 24.4715"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M42.5025 43.3575C42.347 43.9792 42.1916 44.6787 42.0361 45.3005C47.4765 43.9792 52.9169 34.886 53.8496 29.6787"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M13.9015 34.0311C8.84973 40.171 6.82901 49.6528 1 55.0932C1.23316 57.5025 5.35233 57.658 7.13989 58.1243C11.7254 59.2124 16.6217 60.3782 21.2849 60.6113"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M24.8604 61C25.7153 60.6114 26.1039 59.8342 26.7256 59.057C29.2904 55.7927 32.3992 52.9948 35.2748 50.0414"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                  </svg>

                  <p>Dalība ar klubu saistītu lēmumu pieņemšanā</p>
                </div>
              </div>
              <div className="block">
                <div className="inner">
                  <svg
                    width="62"
                    height="62"
                    viewBox="0 0 62 62"
                    className="icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M38.1897 13.9163C38.5796 16.6458 41.1629 19.0341 42.7713 21.0325C46.0857 25.1267 52.9582 32.5841 51.3497 38.4817C49.5463 45.3054 40.8704 33.0227 39.8956 31.5118C36.9224 26.8326 33.7055 22.3485 30.6836 17.7181C31.0735 18.108 31.5122 18.4492 31.9021 18.8879C33.6568 17.2794 35.7526 13.6726 38.1897 13.9163Z"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M47.8887 34.8749C51.4955 39.7977 54.7611 45.1105 58.5142 49.8383C58.5629 49.2534 58.6117 48.4248 58.5629 47.9862C56.3208 51.1543 52.8115 54.6149 51.7392 58.4167C53.2989 58.368 55.1511 54.8099 56.1746 53.6888C57.6368 52.0317 59.3915 50.5694 60.8537 48.9122"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M29.075 18.7904C28.4901 18.2542 26.8817 21.5199 26.2968 22.446C24.1035 25.7603 22.0076 29.026 20.3504 32.6328C19.1319 35.2648 16.4511 40.1876 17.6697 43.307C21.0815 45.013 26.3943 37.5069 27.954 35.5572C30.2936 32.6328 33.9004 28.4898 34.5827 24.6393C33.9004 24.688 33.3642 25.078 33.023 25.7603"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M16.7433 46.0366C14.355 47.7913 12.4054 51.5931 10.6507 54.2251C9.87084 55.3461 8.26239 56.5646 8.06742 57.9294C6.16653 58.7092 2.90089 56.9546 1 56.4672C4.60682 57.4907 7.82372 59.7328 11.3331 61.0001"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M26.0527 22.6897C28.0999 21.7636 33.2664 25.6141 35.1673 26.8326C34.6799 26.7839 34.2412 26.6377 33.8025 26.394"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M27.3203 27.4175C28.9288 27.9049 30.391 28.5385 31.8532 29.3671"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M17.0361 9.09094C19.2782 12.2591 24.9809 14.3062 28.2953 15.7684"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M27.8078 1C27.3692 4.85053 28.3927 7.43379 29.3188 11.1381"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M33.0222 7.87247C32.9735 6.94639 32.876 5.97157 32.7298 5.09424C32.2424 7.48254 32.4861 10.212 32.3398 12.6491"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                  </svg>

                  <p>Regulāri kluba pasākumi un semināri</p>
                </div>
              </div>
              <div className="block">
                <div className="inner">
                  <svg
                    width="63"
                    height="62"
                    viewBox="0 0 63 62"
                    fill="none"
                    className="icon"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M38.8377 32.8919C39.7713 27.6339 41.8844 22.8182 43.7517 17.855C45.8647 12.3022 47.6338 6.60197 49.7468 1C50.6804 1.93366 50.5822 5.91401 51.0244 7.58477C52.7443 14.4644 54.6116 21.1966 55.447 28.2236C49.0588 20.7052 39.4274 17.6093 31.0244 12.9902C36.5281 13.5799 42.3266 11.2703 47.7812 10.5332C50.4347 10.1892 59.2308 7.97789 61.393 10.4349C59.5748 12.3022 56.3807 12.9902 54.1202 14.2187C46.7492 18.3464 40.2627 24.3415 34.5134 30.4349"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M13.8743 35.2506C9.99226 42.3268 5.22568 49.9926 3.21094 57.9042"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M1 42.8673C5.57002 45.2752 11.0246 46.4545 15.8403 48.0762"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M6.4541 40.7051C8.76368 43.4569 9.1568 49.3046 9.74648 52.6953"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M4.29199 47.4373C7.92836 45.1278 14.2183 44.2432 18.2478 43.3096"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M14.4149 27.0933C14.2674 26.9951 14.2674 26.6511 14.6114 27.3882C15.2994 28.9115 15.6925 30.7297 16.1839 32.3022C19.1814 41.7862 20.7048 51.8108 24.5377 61C23.5058 57.167 21.5402 53.6781 20.5574 49.796C19.2797 44.7346 18.9357 39.5749 16.921 34.6609C16.0365 32.5479 14.808 30.2383 15.2994 27.8796C15.8399 24.9803 18.1004 25.5209 20.6065 25.5209C23.948 25.5209 27.1913 25.3243 30.4837 25.226C32.5475 25.1277 32.4493 25.0295 33.3829 26.5037C36.0856 30.8771 35.2011 37.3636 37.5598 41.9828C34.8571 37.1671 26.5033 42.7199 22.4247 41.2457C25.7662 39.5258 32.7932 40.2629 36.5279 40.0663C39.4763 39.9189 42.4738 39.968 45.3731 39.7223C46.4542 44.7838 45.324 53.285 48.862 57.167C48.6163 55.5946 47.5353 54.5626 46.4542 53.1867"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M25.5696 43.8993C24.3902 46.7494 27.8792 54.2678 29.9431 56.3317"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M39.9185 43.0148C38.7883 47.3391 40.3608 52.6954 40.803 57.1671"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                  </svg>

                  <p>Garantētas sēdvietas laukuma priekšā</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="block">
                <div className="inner">
                  <svg
                    width="93"
                    height="63"
                    viewBox="0 0 93 63"
                    fill="none"
                    className="icon"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M91.983 36.918C90.938 39.1311 85.2207 39.5 82.9462 40.1762C76.0609 42.2664 69.483 45.4016 62.7822 47.9836C54.7904 51.1188 46.7986 54.1926 38.9913 57.6967C36.1634 58.9877 33.6429 59.8483 30.5691 60.6475C30.815 60.6475 31.0609 60.6475 31.2454 60.5246C31.0609 57.2664 28.0486 53.0246 26.5732 50.2582C24.8519 46.877 22.8847 43.7418 20.7946 40.5451C20.0568 39.377 19.0732 38.6393 18.397 37.4713C19.565 35.9959 22.2085 34.459 21.1634 32.3074C19.8109 31.8156 16.4298 31.4467 15.3232 32.1844C17.5364 29.9098 22.6388 27.082 25.4667 25.8525C35.7331 21.3647 45.9995 18.7213 56.6347 15.5861C61.6757 14.1107 65.9175 11.0369 71.0814 9.93033C72.6798 9.56148 77.0445 9.7459 77.3519 7.90164C77.4134 7.90164 77.7822 6.85656 78.0281 7.65574C78.274 8.45492 78.4585 8.94672 78.8273 9.80738C81.4093 15.832 84.2371 22.4713 87.9871 27.8197C86.6962 28.5574 82.5158 29.1106 83.0076 31.3852C83.4994 34.0902 88.7863 31.877 89.647 30.4016C89.8314 32.7992 90.4462 35.1352 91.983 36.918Z"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M22.209 62C27.127 52.6557 33.8893 44.4795 40.7131 36.4877C43.418 33.3525 48.1516 26.5902 52.8237 26.1599C53.5614 27.6967 52.7622 29.7254 54.4836 30.5246C55.7745 31.0779 59.6475 27.6353 61.0614 27.3894C62.9057 27.082 64.6885 28.0656 66.5327 28.0041C68.9303 27.9426 71.3893 27.1435 73.7868 26.7131"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M20.6721 49.8893C20.7336 50.1352 20.7336 50.4426 20.7951 50.6885C15.7541 49.2131 11.2049 41.959 7.57787 38.209C5.42623 35.9344 2.90574 33.8442 1 31.3852C2.53688 28.6803 4.01229 28.25 6.59426 26.8361C14.5246 22.4713 22.332 18.291 30.5697 14.6025C38.5615 11.0984 46.9836 5.68852 55.7131 4.39754C55.7131 4.15164 57.7418 2.18443 57.8033 2C60.3237 5.2582 62.4139 8.82377 64.0123 12.6352"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                  </svg>

                  <p>Biļetes biedru darbiniekiem un ģimenēm</p>
                </div>
              </div>
              <div className="block">
                <div className="inner">
                  <svg
                    width="117"
                    height="64"
                    viewBox="0 0 117 64"
                    fill="none"
                    className="icon"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.0473C7.1347 1.99539 12.879 6.23391 17.731 9.80318C26.4311 16.1052 32.3427 24.0803 38.087 33.0593C43.7198 41.8709 50.5237 51.1845 54.3719 61C54.6507 58.1 53.0891 54.1961 52.5872 51.2403C50.8026 40.4767 49.8545 29.8246 51.0814 19.0052C52.0295 10.4167 55.6546 -3.52584 67.1989 2.44155C72.4971 5.17428 76.0664 10.7513 79.1895 15.6033"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M104.063 28.4861C99.1553 25.5303 93.3552 21.3475 87.6109 24.6937C76.7915 30.94 84.4878 49.6787 93.2437 54.196C99.3226 57.3192 111.927 57.8769 115.329 50.5152C117.392 45.9979 115.775 40.3651 110.198 40.9228C104.453 41.5363 101.665 47.0017 100.271 52.021"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M85.1572 25.8092C96.3112 32.5574 105.513 37.5209 112.485 49.6787"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M83.3164 31.6092C88.7261 35.6246 105.011 29.4342 99.9359 22.6302"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                  </svg>

                  <p>
                    Iespēja apmeklēt visas VEF un Latvijas izlases mājas spēles
                    klātienē
                  </p>
                </div>
              </div>
              <div className="block">
                <div className="inner">
                  <svg
                    width="94"
                    height="62"
                    viewBox="0 0 94 62"
                    fill="none"
                    className="icon"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.9237 51.6701C26.3089 49.9154 29.1763 47.6472 30.2462 46.62C33.7128 43.239 37.907 40.2004 40.9884 36.477C39.5333 36.3914 37.9498 37.6754 36.7514 38.4029C31.7441 41.4415 26.5656 44.6085 20.788 45.9353C13.812 47.5188 10.217 43.453 12.4425 36.477C16.4226 24.023 28.2347 13.7088 39.5761 8.0167C51.9018 1.85386 67.9508 -2.29749 80.7901 4.50731C88.0656 8.35908 95.812 13.1524 90.6763 22.4823C85.9686 31.0417 75.6116 35.7923 66.4529 37.4614C62.3871 38.1889 58.2786 38.1461 54.2128 38.7453C52.7149 38.9593 50.4038 39.9436 48.9487 39.6012C47.7076 39.3017 46.6377 38.1033 45.6533 37.6754C44.1554 38.6597 42.7431 39.9008 41.3308 40.9708C35.9811 45.0365 29.1763 51.1994 22.3287 52.0125"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M3.54071 43.7098C3.02714 43.7526 2.51357 43.7526 2 43.7098C2.51357 43.1962 3.28392 42.8111 3.92589 42.4687C9.36117 39.1305 14.7109 35.7923 20.1033 32.4541C28.7056 27.19 37.3935 20.8559 46.6806 16.8758C45.525 18.3737 43.9843 20.8131 44.7975 22.8246C49.2056 25.3497 55.1117 18.0313 56.7808 14.5647C53.5282 15.0783 51.2599 19.4008 56.2244 19.0157C58.7495 18.8445 69.2348 16.619 70.0908 13.6232C67.4374 10.9269 59.4342 20.8987 66.025 20.7704C68.3361 20.1284 70.5188 19.144 72.5303 17.7745C75.8685 15.763 79.0783 13.5804 82.1597 11.2693C83.8716 9.94259 89.3069 6.64718 89.2213 3.90814C88.1941 4.07933 87.4666 5.10647 86.8674 5.87683C83.7432 10.0282 81.4321 14.8643 78.8643 19.358C70.8184 33.3956 61.3173 46.7056 53.7422 61"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M61.2314 25.6493C62.601 23.9374 66.196 23.1242 68.1218 22.4395C76.4246 19.4436 84.8557 17.3038 93.5007 15.5919"
                      stroke="#999999"
                      strokeWidth="1"
                      vectorEffect="non-scaling-stroke"
                      strokeMiterlimit="10"
                    />
                  </svg>

                  <p>“VEF” un “VEF Skola” jūsu uzņēmuma komunikācijā</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
