import React, { Component } from 'react';
import luxy from 'luxy.js';

import { Intro } from './containers/intro';
import { Privilegies } from './containers/privilegies';
import { WhoWeAre } from './containers/whoweare';
import { Members } from './containers/members';
import { VEFRiga } from './containers/vefriga';

export default class Home extends Component {
  componentDidMount() {
    luxy.resize();

    window.addEventListener('hashchange', this.scroll);
    window.scrollTo(0, 0);
  }
  scroll() {
    if (window.location.hash === '#privilegijas') {
      setTimeout(() => {
        const members = document.querySelector('.member-privilegies');

        window.scrollTo({
          top: members.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }, 300);
    }
  }
  render() {
    return (
      <div className="page home">
        <Intro />
        <Privilegies />
        <WhoWeAre />
        {/* <Members /> */}
        <VEFRiga />
      </div>
    );
  }
}
